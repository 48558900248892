<template>
  <div class="footer">
    <div class="pc" v-if="$store.state.note.userAgent == 1">
      <div class="logo">
        <img src="@/assets/img/logo.png" alt="" />
      </div>
      <div class="footer_img">
        <img class="hoverw"  src="@/assets/img/sns_insta.png" alt=""  @click="goLinkck()" />
      </div>
      <div class="pos_imga" v-if="centerDialogVisible">
        <img src="@/assets/img/img_c.png" alt="" />
      </div>
      <div class="pos_imgb">
        <img src="@/assets/img/img_d.png" alt="" />
      </div>
      <div class="footer_box">
        <div class="box_item box_active" @click="goLink('monster')">
          Office Monsterとは
        </div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('comment')">ご意見・ご要望</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('question')">よくある質問</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('service')">サービス利用規約</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('perinfo')">
          個人情報の取り扱い
        </div>
      </div>
      <div class="footer_box">
        <div class="box_item" @click="goLink('link')">リンクポリシー</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLinkc()">掲載依頼のお問い合わせ</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLinka()">採用情報</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLinkb()">運営会社</div>
      </div>
      <div class="footer_box">
        <div class="box_item">© 株式会社ライトハウス</div>
      </div>
    </div>
    <div class="mobile" v-else>
      <div class="logo">
        <img src="@/assets/img/logo.png" alt="" />
      </div>
      <div class="footer_img">
        <img class="hoverw" src="@/assets/img/sns_insta.png" alt="" @click="goLinkck()" />
      </div>
      <div class="pos_imga" v-if="centerDialogVisible">
        <img src="@/assets/img/img_c.png" alt="" />
      </div>
      <div class="pos_imgb">
        <img src="@/assets/img/img_d.png" alt="" />
      </div>
      <div class="footer_box">
        <div class="box_item box_active" @click="goLink('mmonster')">
          Office Monsterとは
        </div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('mcomment')">ご意見・ご要望</div>
      </div>
      <div class="footer_box">
        <div class="box_item" @click="goLink('mquestion')">よくある質問</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('mservice')">サービス利用規約</div>
      </div>
      <div class="footer_box">
        <div class="box_item" @click="goLink('mperinfo')">
          個人情報の取り扱い
        </div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLink('mlink')">リンクポリシー</div>
      </div>
      <div class="footer_box">
        <div class="box_item" @click="goLink('mconsult')">
          掲載依頼のお問い合わせ
        </div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLinka()">採用情報</div>
        <div class="box_line"></div>
        <div class="box_item" @click="goLinkb()">運営会社</div>
      </div>
      <div class="footer_box">
        <div class="box_item">© 株式会社ライトハウス</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  watch: {
    $route(to) {
      if (to.name == "index" || to.name == "mobileindex") {
        this.centerDialogVisible = true;
      } else {
        this.centerDialogVisible = false;
      }
    },
  },
  mounted() {
    // console.log(this.$route);
  },
  methods: {
    goLink(e) {
      this.$router.push({ name: e });
    },
    goLinkc() {
      this.$router.push({ name: "consult", query: { conRadio: 2 } });
    },
    goLinka() {
      window.open("https://www.lhthus.co.jp/recruit/", "_blank");
    },
    goLinkb() {
      window.open("https://www.lhthus.co.jp/", "_blank");
    },
    goLinkck() {
      window.open("https://www.instagram.com/lhthus.jp_?igsh=dDF1Z3JzYnVuYmN4/", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100%;

  .pc {
    width: 100%;
    height: 100%;
    position: relative;

    .logo {
      width: 353px;
      height: 63px;
      margin: 40px auto 16px auto;
    }

    .footer_img {
      width: 29px;
      height: 29px;
      margin: 0 auto 16px auto;
      .hoverw{
        cursor: pointer;
      }
    }

    .footer_box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      .box_item {
        text-align: left;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }

      .box_line {
        width: 2px;
        height: 15px;
        background: #fff;
        margin: 0 10px;
      }

      .box_active {
        color: #ffd300 !important;
      }
    }

    .pos_imga {
      width: 174px;
      height: 219px;
      flex-shrink: 0;
      position: absolute;
      right: 0;
      top: -220px;
    }

    .pos_imgb {
      width: 134px;
      height: 97px;
      position: absolute;
      bottom: 40px;
      left: 10%;
    }
  }

  .mobile {
    width: 100%;
    height: 100%;
    position: relative;

    .logo {
      width: 279px;
      height: 53px;
      margin: 40px auto 16px auto;
    }

    .footer_img {
      width: 29px;
      height: 29px;
      margin: 0 auto 16px auto;
    }

    .footer_box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      .box_item {
        text-align: left;
        color: #fff;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }

      .box_line {
        width: 2px;
        height: 15px;
        background: #fff;
        margin: 0 10px;
      }

      .box_active {
        color: #ffd300 !important;
      }
    }

    .pos_imga {
      width: 92px;
      height: 115px;
      flex-shrink: 0;
      position: absolute;
      right: 0;
      top: -130px;
    }

    .pos_imgb {
      width: 134px;
      height: 97px;
      position: absolute;
      transform: translateX(-50%);
      bottom: 40px;
      left: 50%;
    }
  }
}
</style>
